import {useCallback, useContext, useEffect, useState} from "react";
import {FormattedMessage, IntlShape, WrappedComponentProps, injectIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {RouteComponentProps} from "react-router-dom";
import {Button, Col, Collapse, Modal, ModalBody, Row} from "reactstrap";
import {AnyAction} from "redux";
import {ThunkDispatch} from "redux-thunk";
import {ApiActions} from "../../actions/api-actions";
import {DetailToggleButton} from "../../components/detail-toggle-button";
import {LoadingIcon} from "../../components/icons/icons";
import {ActionTypes} from "../../enums/action-types";
import {getPriceString} from "../../helpers/localization";
import AppleWalletBadge from "../../images/en_add_to_apple_wallet.png";
import GoogleWalletBadge from "../../images/en_add_to_google_wallet.png";
import {MobileTicketCart, MobileTicketCartItem} from "../../models/mobile-ticket-cart";
import {RootState} from "../../reducers";
import {ConfigContext} from "../public-ticket-app";
import AddressLink from "../../helpers/address-link";
import {initiateApplePassDownload} from "../../helpers/utilities"

interface MobileTicketsProps extends RouteComponentProps<any>, WrappedComponentProps {}

const MobileTicketLanding = (props: MobileTicketsProps) => {
	const {intl, match} = props;

	const [mobileTicketCart, setMobileTicketCart] = useState<MobileTicketCart>();
	const [processing, setProcessing] = useState<boolean>(false);
	
	const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
	const config = useContext(ConfigContext);
	
	useEffect(() => {
		let eiFilter: string = '';
		let toiFilter: string[] = [];
		if(!!match.params.object) {
			if(match.params.object === 'ei') {
				eiFilter = match.params.ids;
			} else if (match.params.object === 'toi') {
				toiFilter = decodeURIComponent(match.params.ids).split(',');
			}
		}
		
		dispatch(ApiActions.fetchMobileTicketCart(match.params.ticketOrderId,match.params.token, eiFilter, toiFilter))
			.then((response) => {
				if (response.type === ActionTypes.API_SUCCESS) {
					setMobileTicketCart(response.data);
				}
			});
	}, [dispatch, match.params.ticketOrderId, match.params.filter, match.params.token, match.params.object, match.params.ids]);
	
	const printButton = (
		<Button className='float-right' onClick={() => window.print()}>
			<FormattedMessage id="lbl_PrintOrder" />
		</Button>
	);
	
	if(!mobileTicketCart) {
		return null;
	}
	
	return (
		<div className='mobile-record-display'>
			<Modal isOpen={processing} size='sm' className="text-dark pt-5" aria-modal="true" aria-labelledby="ProcessingModalLabel">
				<ModalBody className='text-center'>
					<h4 className='pb-2' id="ProcessingModalLabel">{intl.formatMessage({id: "msg_generating_ticket"})}</h4>
					<LoadingIcon size={24}/>
				</ModalBody>
			</Modal>

			<div className='border-bottom'>
				<Row>
					<Col xs={6} md={3} className='pb-4'>
						<div>{window.PublicTicketApp.orgName}</div>
						<div>{mobileTicketCart.name}</div>
						<div>{mobileTicketCart.buyerName}</div>
					</Col>
					<Col xs={6} className='d-print-none d-md-none'>{printButton}</Col>
					<Col xs={12} md={6} className='pb-4'>
						<div className='small text-center'>{config.onlineTicketText}</div>
					</Col>
					{/* Print button is listed twice to allow different placement on mobile vs desktop */}
					<Col md={3} className='d-print-none d-none d-md-block'>{printButton}</Col>
				</Row>
			</div>
			
			{mobileTicketCart.cartItems.map((ci => {
				return <MobileTicket key={ci.id} currencyCode={config.currencyCode} cartItem={ci} intl={intl} setProcessing={setProcessing} token={match.params.token}/>;
			}))}
			
			<Row className='mt-4'>
				<Col className='text-center white-space-pre-wrap'>
					<div>{config.mobileTicketOrgDetails}</div>
				</Col>
			</Row>
		</div>
	);
}
export default injectIntl(MobileTicketLanding);

interface MobileTicketProps {
	cartItem: MobileTicketCartItem;
	currencyCode: string;
	intl: IntlShape;
	setProcessing: (props: any) => void;
	token: string;
}

const MobileTicket = (props: MobileTicketProps) => {
	const {cartItem, currencyCode, intl, setProcessing, token} = props;
	
	const priceString = getPriceString(intl, currencyCode, {minPrice: Number(cartItem.etp)})
	const barcodeAltText = `${intl.formatMessage({id: "lbl_BarcodeFor"})} ${cartItem.teName} ${cartItem.eiName}`;
	const sectionLabel = intl.formatMessage({id: "lbl_Section"}); 
	const rowSeatLabel = intl.formatMessage({id: "lbl_Row"}) + ' / ' + intl.formatMessage({id: "lbl_Seat"})

	const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();
	
	const addToGoogleWallet = useCallback(() => {
		setProcessing(true);
		dispatch(ApiActions.fetchGooglePassURL(cartItem.id, token))
			.then((response) => {
				if (response.type === ActionTypes.API_SUCCESS) {
					window.open(response.data, '_blank', 'noreferrer')
				}
			})
			.finally(() => setProcessing(false));
	},[cartItem.id, dispatch, setProcessing, token]);

	const addToAppleWallet = useCallback(() => {
		setProcessing(true);
		dispatch(ApiActions.fetchApplePass(cartItem.id, token))
			.then((response) => {
				if (response.type === ActionTypes.API_SUCCESS) {
					initiateApplePassDownload(response.data, cartItem.name);
				}
			})
			.finally(() => {
				setProcessing(false);
			});
	},[cartItem.id, cartItem.name, dispatch, setProcessing, token]);
	
	return (
		<div className='mt-4 pb-4 border-bottom mobile-record'>
			<Row>
				<Col>
					<h4 className='text-center text-md-left'>{cartItem.teName}</h4>
				</Col>
			</Row>
			<Row>
				<Col xs={6} md={4} className='col-print-4'>
					<div className='mb-4'>
						<div>{cartItem.eiName}</div>
					</div>
					
					{!!cartItem.section ? (
						<div className='mb-4'>
							<div>{sectionLabel}: {cartItem.section}</div>
							<div>{`${rowSeatLabel}: ${cartItem.row} / ${cartItem.seat}`}</div>
							{!!cartItem.ticketNote && <div>{intl.formatMessage({id: "lbl_Note"})}: {cartItem.ticketNote}</div>}
						</div>
					) : (
						<div className='mb-4'>{cartItem.allocName}</div>
					)}

					<div className='mb-4'>
						<div>{cartItem.levelName}</div>
						<div>{intl.formatMessage({id: "lbl_Price"})}: {priceString}</div>
					</div>
				</Col>
				<Col xs={6} md={4} className='text-right col-print-4'>
					<div className='mb-4'>
						<div>{cartItem.venueName}</div>
						{!!cartItem.venueAddr && (
							<AddressLink address={cartItem.venueAddr} />
						)}
					</div>
					<div className='d-none d-md-block d-print-none'>
						<img className='btn' src={GoogleWalletBadge} alt={intl.formatMessage({id: "lbl_AddToGoogleWallet"})} onClick={addToGoogleWallet}/>
						<img className='btn' src={AppleWalletBadge} alt={intl.formatMessage({id: "lbl_AddToAppleWallet"})} onClick={addToAppleWallet}/>
					</div>
				</Col>
				<Col xs={12} md={4} className='text-center col-print-4'>
					<img src={cartItem.barcodeURL} alt={barcodeAltText}/>
					<div className='mt-1 text-center'>{cartItem.name}</div>
				</Col>
				{/* Add to wallet buttons are listed twice to allow different placement on mobile vs desktop */}
				<Col xs={12} className='d-md-none d-print-none text-center mt-2'>
					<div>
						<img className='btn' src={GoogleWalletBadge} alt={intl.formatMessage({id: "lbl_AddToGoogleWallet"})} onClick={addToGoogleWallet}/>
						<img className='btn' src={AppleWalletBadge} alt={intl.formatMessage({id: "lbl_AddToAppleWallet"})} onClick={addToAppleWallet}/>
					</div>
				</Col>
			</Row>

			<MobileTicketMoreDetails cartItem={cartItem} />
		</div>
	)
} 

interface MobileTicketMoreDetailsProps {
	cartItem: MobileTicketCartItem;
}

const MobileTicketMoreDetails = (props: MobileTicketMoreDetailsProps) => {
	const {teRunTime, venueTicketNote, teMobDetails, eiMobDetails} = props.cartItem;
	const [moreInfoOpen, setMoreInfoOpen] = useState<boolean>(false);
	
	// If nothing to show, display nothing
	if(!teRunTime && !venueTicketNote && !teMobDetails && !eiMobDetails) {
		return null;
	}
	
	return (
		<div className='mt-3 mt-md-2'>
			<span className='d-print-none'><DetailToggleButton onClick={()=>setMoreInfoOpen(prevState => !prevState)} detailsVisible={moreInfoOpen} /></span>
			<Collapse className='d-print-block' isOpen={moreInfoOpen}>
				<Row className='mt-4'>
					<Col className='pr-5'>
						{!!venueTicketNote && <div className={'pb-3'} style={{ whiteSpace: 'pre-wrap'}}>{venueTicketNote}</div>}
						{!!teMobDetails && <div style={{ whiteSpace: 'pre-wrap'}}>{teMobDetails}</div>}

					</Col>
					<Col>
						{!!teRunTime && (
							<>
								<div><FormattedMessage id={'lbl_RunTime'} /></div>
								<div className={'pb-3'}>{teRunTime}</div>
							</>
						)}
						{!!eiMobDetails && <div style={{ whiteSpace: 'pre-wrap'}}>{eiMobDetails}</div>}
					</Col>
				</Row>
			</Collapse>
		</div>
	)
}