import {SeatingTypes} from "../enums/seating-types";
import {SubscriptionTypes} from "../enums/subscription-types";
import {TicketableEventTypes} from "../enums/ticketable-event-types";
import {SubscriptionBuyerSelectionLinkDescriptor} from "./subscription-buyer-selection-link-descriptor";

export class CartItemFees {
	public fees?: number;
	public fee2?: number;
	public fee3?: number;
	public fee4?: number;
	public fee5?: number;
}

export class CartItem extends CartItemFees {
	public allocId: string = '';
	public allocName: string = '';
	public eiId: string = '';
	public eiName: string = '';
	public id: string = '';
	public itemTotal: number = 0;
	public qty: number = 0;
	public levelId: string = '';
	public levelName: string = '';
	public name: string = '';
	public teId: string = '';
	public teName: string = '';
	public toId: string = '';
	public toName: string = '';
	public unitPrice: number = 0;
	
	public amtDue?: number;
	public benefitId?: string;
	public contentFormat?: string;
	public customCartText?: string;
	public disAmt?: number;
	public disId?: string;
	public disName?: string;
	public disTotal?: number;
	public disType?: string;
	public etp?: number;
	public itemDetail?: string;
	public itemFeeTotal?: number;
	public liveStreamUrl?: string;
	public passcode?: string;
	public preDisTotal?: number;
	public salesTax?: number;
	public sbslId?: string;
	public sbsls?: SubscriptionBuyerSelectionLinkDescriptor[];
	public seatAssign?: string;
	public seatId?: string;
	public seatKey?: string;
	public seatingType?: SeatingTypes;
	public seatNote?: string;
	public sectionId?: string;
	public soiId?: string;
	public soiStatus?: string;
	public splId?: string;
	public status?: string;
	public stoiId?: string;
	public subsItemDetail?: string;
	public subsType?: SubscriptionTypes;
	public subtotal?: number;
	public ticketNote?: string;
	public ticketType?: TicketableEventTypes;
	public toModstamp?: number;
	public unitFee?: number;
	public unitFee2?: number;
	public unitFee3?: number;
	public unitFee4?: number;
	public unitFee5?: number;
	public venueId?: string;

	// Sadly, the methods below can't be instance methods, because we construct CartItems from JSON, rather than via a constructor
	/**
	 * Returns true if this CartItem fulfills an SBSL
	 */
	static isFulfillmentItem = (ci: CartItem): boolean => {
		// A fulfillment item will have a truthy value for sbslId and stoiId
		return !!ci.sbslId && !!ci.stoiId && CartItem.isTicketsItem(ci);
	}

	static isMembershipItem = (ci: CartItem): boolean => {
		return ci.ticketType === TicketableEventTypes.MEMBERSHIP;
	}
	
	static isSubscriptionItem = (ci: CartItem): boolean => {
		return ci.ticketType === TicketableEventTypes.SUBSCRIPTION;
	}

	static isChooseYourOwnSubscription = (ci: CartItem): boolean => {
		return CartItem.isSubscriptionItem(ci) && ci.subsType === SubscriptionTypes.CHOOSE_YOUR_OWN;
	}

	static isFixedSubscription = (ci: CartItem): boolean => {
		return CartItem.isSubscriptionItem(ci) && ci.subsType === SubscriptionTypes.FIXED;
	}
	
	static isPickLaterSubscription = (ci: CartItem): boolean => {
		return CartItem.isSubscriptionItem(ci) && ci.subsType === SubscriptionTypes.PICK_LATER;
	}
	
	static isTicketsItem = (ci: CartItem): boolean => {
		return ci.ticketType === TicketableEventTypes.TICKETS;
	}
	
	static isRetailTicketsItem = (ci: CartItem): boolean => {
		return CartItem.isTicketsItem(ci) && !CartItem.isFulfillmentItem(ci);
	}
	
	static isRetailItem(ci: CartItem): boolean {
		return CartItem.isMembershipItem(ci) || CartItem.isSubscriptionItem(ci) || CartItem.isRetailTicketsItem(ci);
	}
	
	static isPYOSItem(ci: CartItem): boolean {
		return ci.seatingType === SeatingTypes.PYOS;
	}
	
	static isGAItem(ci: CartItem): boolean {
		return ci.seatingType === SeatingTypes.GA;
	}
}