export const enum Paths {
	ROOT = "/",
	EVENTS = "/(events)?(/expanded|/condensed|/calendar)?",
	EVENTS__EXPANDED = "/events/expanded",
	EVENTS__CONDENSED = "/events/condensed",
	EVENTS__CALENDAR = "/events/calendar",
	EVENT = "/events/:ticketableEventId",
	SUBSCRIPTIONS = "/subscriptions",
	EXPIRED_PASSWORD = "/portal/expiredPassword",
	MOBILE_TICKETS = "/mobileTickets/:ticketOrderId/:token", 
	MOBILE_TICKETS_WITH_FILTER = "/mobileTickets/:ticketOrderId/:token/:object/:ids",
	MEMBERSHIP_CARD = "/membershipCards/:tokens",
	
	//
	// Membership routes
	//
	
	MEMBERSHIPS = "/memberships",
	MEMBERSHIP = "/memberships/:ticketableEventId",
	COMPLETE = "/complete",
	
	//
	// Instance Routes
	//
	
	INSTANCE = "/instances/:eventInstanceId",
	INSTANCE__VENUE = "/instances/:eventInstanceId/venue",
	INSTANCE__SECTION = "/instances/:eventInstanceId/sections/:sectionId",
	INSTANCE__SECTION_GROUP = "/instances/:eventInstanceId/sectionGroups/:sectionGroupId",
	
	//
	// Fulfillment Routes
	//
	
	// Multi-Venue fixed subscription fulfillment routes (fulfill by venue)
	FULFILLMENT = "/fulfillmentInstances/:eventInstanceId/allocations/:allocationId",
	FULFILLMENT_VENUE = "/fulfillmentInstances/:eventInstanceId/allocations/:allocationId/venues/:venueId",
	FULFILLMENT_SECTION = "/fulfillmentInstances/:eventInstanceId/allocations/:allocationId/venues/:venueId/sections/:sectionId",
	
	// CYO subscription fulfillment routes (fulfill by event instance)
	FULFILLMENT_INSTANCE = "/fulfillmentInstances/:eventInstanceId/allocations/:allocationId/instances/:fulfillmentInstanceId",
	FULFILLMENT_INSTANCE_SECTION = "/fulfillmentInstances/:eventInstanceId/allocations/:allocationId/instances/:fulfillmentInstanceId/sections/:sectionId",
	
	//
	// Cart Routes
	//
	
	CART = "/cart",
	CART__DELIVERY = "/cart/delivery",
	CART__DONATION = "/cart/donation",
	CART__DISCOUNT = "/cart/discount",
	CART__CONTACT = "/cart/contact",
	CART__PAYMENT = "/cart/payment",
	
	//
	// Portal Routes
	//
	
	PORTAL = "/portal",
	PORTAL__CHANGE_PASSWORD = "/portal/changePassword",
	PORTAL__DONATIONS = "/portal/donations",
	PORTAL__PROFILE = "/portal/profile",
	PORTAL__ORDERS = "/portal/orders",
	PORTAL__ORDER = "/portal/orders/:ticketOrderId",
	PORTAL__RENEWABLE_BENEFITS = "/portal/renewableBenefits",
	PORTAL__RENEWABLE_BENEFIT__CONTACT_REQUEST = "/portal/renewableBenefits/:benefitId/contactRequest",
	PORTAL__PENDING_RENEWALS = "/portal/pendingRenewals",
	PORTAL__PENDING_RENEWAL = "/portal/pendingRenewals/:ticketOrderId",
	PORTAL__PENDING_RENEWAL__DELIVERY = "/portal/pendingRenewals/:ticketOrderId/delivery",
	PORTAL__PENDING_RENEWAL__DONATION = "/portal/pendingRenewals/:ticketOrderId/donation",
	PORTAL__PENDING_RENEWAL__DISCOUNT = "/portal/pendingRenewals/:ticketOrderId/discount",
	PORTAL__PENDING_RENEWAL__CONTACT = "/portal/pendingRenewals/:ticketOrderId/contact",
	PORTAL__PENDING_RENEWAL__PAYMENT = "/portal/pendingRenewals/:ticketOrderId/payment",
	PORTAL__PENDING_RENEWAL__CONTACT_REQUEST = "/portal/pendingRenewals/:ticketOrderId/contactRequest",
	
	//
	// Redirects for Legacy Routes
	//
	
	/**
	 * This route is deprecated, this is to be used for legacy support only. Route for Ticketable events including Subscriptions,
	 *
	 * @deprecated use {@link EVENT} instead
	 */
	LEGACY_TICKETABLE_EVENT_DETAIL = "/details_:ticketableEventId",
	/**
	 * This route is deprecated, this is to be used for legacy support only. Route for event instances including subscriptions
	 *
	 * @deprecated use {@link INSTANCE} instead
	 */
	LEGACY_EVENT_INSTANCE_DETAIL = "/sections_:eventInstanceId",
	/**
	 * This route is deprecated, this is to be used for legacy support only. Route for Subscriptions,
	 *
	 * @deprecated use {@link MEMBERSHIPS} instead
	 */
	LEGACY_MEMBERSHIP_LIST = "/membership_:ticketableEventId",
}