import {DeliveryMethods} from "../enums/delivery-methods";
import {ApplicationMessage} from "./application-message";
import {BasicStringKeyedMap} from "./basic-map";
import {CartItem} from "./cart-item";

export class Cart {
	public amtDue: number = 0;
	public cartId: string = ''; // 20 character random string used
	public cartItems: CartItem[] = [];
	public customFields: BasicStringKeyedMap<any> = {};
	public deliveryMethods: string[] = [];
	public delMethod: DeliveryMethods | '' = '';
	public id: string = '';  // aka the ticket order id
	public orderTotal: number = 0;
	public subtotal: number = 0;

	public accessRequirements?: string;
	public acctId?: string;
	public address?: string;
	public amtTendered?: string;
	public badges?: any[];
	public bluefinCardInfo?: string;
	public cartExpiration?: number;
	public ccNumber?: string;
	public ccExpMonth?: string;
	public ccExpYear?: string;
	public ccCvv2?: string;
	public ccType?: string;
	public city?: string;
	public comments?: string;
	public contactEmail?: string;
	public contactId?: string;
	public contactName?: string;
	public contactPhone?: string;
	public contactType?: string;
	public contactPronouns?: string;
	public country?: string;
	public deliveryFee?: number;
	public donationAmt?: number;
	public email?: string;
	public firstName?: string;
	public gcNumber?: string;
	public gcHash?: string;
	public gcBalance?: number;
	public itemFeeTotal?: number;
	public itemSubTotal?: number;
	public lastName?: string;
	public memberDiscountsApplied?: boolean;
	public modstamp?: number;
	public name?: string;
	public nameOnCard?: string;
	public optIn?: boolean;
	public leapMarketingOptIn?: boolean;
	public orderEntitlements?: BasicStringKeyedMap<number>;
	public orderFeeState?: string;
	public orderFeeTotal?: number;
	public orderSource?: string;
	public orderStatus?: string;
	public orderType?: string;
	public partiallyPaid?: boolean;
	public paymentAmount?: string;
	public paymentToken?: string;
	public phone?: string;
	public pronouns?: string;
	public postalCode?: string;
	public salutation?: string;
	public salesTaxTotal?: number;
	public saveCardOnFile?: boolean;
	public saveGiftCardOnFile?: boolean;
	public seatUpgradeRequested?: boolean;
	public selectedPM?: string;
	public selectedGiftCardPM?: string;
	public sendConfEmail?: boolean;
	public shippingAddress?: string;
	public shippingCity?: string;
	public shippingCountry?: string;
	public shippingFirstName?: string;
	public shippingLastName?: string;
	public shippingPostalCode?: string;
	public shippingSalutation?: string;
	public shippingState?: string;
	public state?: string;
	public userAgent?: string;
	public validated?: boolean;
	public validationErrors?: ApplicationMessage[];
}