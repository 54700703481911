/* In Chrome and Firefox, Apple maps URLs are automatically intercepted and redirected
to Google Maps, but Apple doesn't return the favor in Safari, so defaulting to apple
should allow this link to open the native maps app across all devices */

const AddressLink = ({ address }: { address: string }) => {
	return (
		<div className='white-space-pre-wrap'>
			<a target='_blank' rel='noreferrer' href={'http://maps.apple.com/?address=' + encodeURI(address)}>
				{address}
			</a>
		</div>
	)
}

export default AddressLink;