import { RouteComponentProps } from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import { ApiActions } from "../../actions/api-actions";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RootState } from "../../reducers";
import { ActionTypes } from "../../enums/action-types";
import { MembershipCardWrapper, MembershipCard } from "../../models/membership-card";
import {Col, Row, Button, Modal, ModalBody} from "reactstrap";
import AppleWalletBadge from "../../images/en_add_to_apple_wallet.png";
import GoogleWalletBadge from "../../images/en_add_to_google_wallet.png";
import { FormattedMessage, injectIntl, WrappedComponentProps, IntlShape } from "react-intl";
import AddressLink from "../../helpers/address-link";
import {LoadingIcon} from "../../components/icons/icons";
import {initiateApplePassDownload} from "../../helpers/utilities";


interface MembershipCardLandingProps extends RouteComponentProps<any>, WrappedComponentProps {}

const MembershipCardLanding = (props: MembershipCardLandingProps) => {
	const { intl, match } = props;

	const [membershipCardWrapper, setMembershipCardWrapper] = useState<MembershipCardWrapper>();
	const [processing, setProcessing] = useState<boolean>(false);

	const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

	useEffect(() => {
		dispatch(ApiActions.fetchMembershipCards(decodeURIComponent(match.params.tokens).split(','))).then((response) => {
			if(response.type === ActionTypes.API_SUCCESS) {
				setMembershipCardWrapper(response.data);
			}
		});
	}, [dispatch, match.params.tokens]);

	const printButton = (
		<Button className='float-right' onClick={() => window.print()}>
			<FormattedMessage id="lbl_Print" />
		</Button>
	);

	if(!membershipCardWrapper) {
		return null;
	}

	return (
		<div className='mobile-record-display'>
			<Modal isOpen={processing} size='sm' className="text-dark pt-5" aria-modal="true" aria-labelledby="ProcessingModalLabel">
				<ModalBody className='text-center'>
					<h4 className='pb-2' id="ProcessingModalLabel">{intl.formatMessage({id: "msg_generating_membership_card"})}</h4>
					<LoadingIcon size={24}/>
				</ModalBody>
			</Modal>
			
			
			<Row className='membership-card-header border-bottom pb-5'>
				<Col>
					<h1>{window.PublicTicketApp.orgName}</h1>
				</Col>
				<Col className='d-print-none'>
					{printButton}
				</Col>
			</Row>

			{membershipCardWrapper.cards.map((card, index) => {
				return <MembershipCardInfo 
					key={card.id} 
					card={card} 
					index={index} 
					orgDetails={membershipCardWrapper.orgDetails}
					orgUrl={membershipCardWrapper.orgUrl}
					orgAddress={membershipCardWrapper.orgAddress}
					intl={intl} 
					setProcessing={setProcessing}
				/>;
			})}
		</div>
	);
}

export default injectIntl(MembershipCardLanding);

interface MembershipCardProps {
	card: MembershipCard;
	index: number;
	orgDetails: string;
	orgUrl: string;
	orgAddress: string;
	intl: IntlShape;
	setProcessing: any;
}

const MembershipCardInfo = (props: MembershipCardProps) => {
	const { card, index, orgDetails, intl, orgUrl, orgAddress, setProcessing } = props;
	const barcodeAltText = `${intl.formatMessage({id: 'lbl_BarcodeFor'})} ${card.benefitName} ${card.benefitLevelName}`;

	const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

	const addToGoogleWallet = useCallback(() => {
		setProcessing(true);
		dispatch(ApiActions.fetchGooglePassURL(card.id, card.token))
			.then((response) => {
				if (response.type === ActionTypes.API_SUCCESS) {
					window.open(response.data, '_blank', 'noreferrer')
				}
			})
			.finally(() => {
				setProcessing(false);
			});
	},[card.id, card.token, dispatch, setProcessing]);
	
	const addToAppleWallet = useCallback(() => {
		setProcessing(true);
		dispatch(ApiActions.fetchApplePass(card.id, card.token))
			.then((response) => {
				if (response.type === ActionTypes.API_SUCCESS) {
					initiateApplePassDownload(response.data, card.benefitName);
				}
			})
			.finally(() => {
				setProcessing(false);
			});
	},[card.benefitName, card.id, card.token, dispatch, setProcessing]);
	
	return (
		<div key={index} className='border-bottom mt-5 pb-5 mobile-record'>
			<Row className='mb-5'>
				<Col xs={12} sm={6} md={3} className='pb-2'>
					<div>{intl.formatMessage({id: card.benefitMembershipType === 'Household' ? 'lbl_MemberNameHousehold' : 'lbl_MemberNameIndiv' })}</div>
					<div className='lead'>{card.benefitMembershipType === 'Household' ? card.accountName : `${card.contactFirstName} ${card.contactLastName}`}</div>
				</Col>
				<Col xs={12} sm={6} md={2} className='pb-2'>
					<div>{intl.formatMessage({id: "lbl_MemberLevel"})}</div>
					<div className='lead'>{card.benefitLevelName}</div>
				</Col>
				<Col xs={12} sm={6} md={2} className='pb-2'>
					<div>{intl.formatMessage({id: "lbl_MemberExpDate"})}</div>
					<div className='lead'>{card.expDate}</div>
				</Col>
				<Col xs={12} sm={6} md={2} className='pb-2'>
				<div>{intl.formatMessage({id: "lbl_MemberStatus"})}</div>
					<div className='lead'>{card.status}</div>
				</Col>
			</Row>
			
			<Row>
				<Col xs={12} md={4} className='text-center col-print-4 pb-3'>
					{card.status === 'Active' && <img className='mb-1' src={card.barcodeURL} alt={barcodeAltText}/>}
					<div className='text-center'>{card.benefitName}</div>
					{card.status === 'Active' && 
						<>
							<div className='d-print-none'>
								<img className='btn' src={GoogleWalletBadge} alt={intl.formatMessage({id: 'lbl_AddToGoogleWallet'})} onClick={addToGoogleWallet}/>
							</div>
							<div className='d-print-none'>
								<img className='btn' src={AppleWalletBadge} alt={intl.formatMessage({id: 'lbl_AddToAppleWallet'})} onClick={addToAppleWallet}/>
							</div>
						</>
					}
				</Col>
				<Col className='pb-3'>
					{!!orgDetails && 
						<div className='white-space-pre-wrap mb-3'>
							{orgDetails}
						</div>
					}
					{!!card.cardDetails && 
						<div className='white-space-pre-wrap mb-3'>
							{card.cardDetails}
						</div>
					}
					{!!orgUrl && 
						<div className="mb-3">
							<a target='_blank' rel='noreferrer' href={orgUrl}>
								{orgUrl}
							</a>
						</div>
					}
					{!!orgAddress && 
						<AddressLink address={orgAddress} />
					}
				</Col>
			</Row>
		</div>
	);
}