import {AnyAction, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";
import VfRemote from "../api/vf-remote";
import {ActionTypes} from "../enums/action-types";
import {ApiActionNames} from "../enums/api-action-names";
import {getSanitizedCart} from "../helpers/utilities";
import {CartItem} from "../models/cart-item";
import {UserProfile} from "../models/public-ticket-app/user-profile";
import {RootState} from "../reducers";
import {BasicStringKeyedMap} from "../models/basic-map";

/**
 * Class whose methods create visual force http request actions to be dispatched
 *
 * @see VfRemote for class that does the actual http request
 */
export class ApiActions {
	public static fetchGlobalConfig(draftTheme: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_GLOBAL_CONFIG, false,{draftTheme});
	}
	
	public static fetchLocalizedMessages(): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_LOCALIZED_MESSAGES, false);
	}

	/**
	 * Gets list of ticketable events
	 *
	 * @returns ThunkAction<Promise<TicketableEvent[]> if successful, otherwise ThunkAction<Promise<VfRemoteErrorResponse>
	 */
	public static fetchEvents(): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_EVENTS, false, {siteURL: window.PublicTicketApp.siteURL, accessCode: window.PublicTicketApp.accessCode, passcode: window.PublicTicketApp.passcode});
	}

	public static fetchEvent(eventId: string): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_EVENT, false, {eventId, siteURL: window.PublicTicketApp.siteURL, accessCode: window.PublicTicketApp.accessCode, passcode: window.PublicTicketApp.passcode});
	}

	/**
	 * Gets the event descriptor for the specified event instance
	 *
	 * @param {string} eiId the event instance id
	 * @returns ThunkAction<Promise<EventDescriptor> if successful, otherwise ThunkAction<Promise<VfRemoteErrorResponse>
	 */
	public static fetchEventDescriptor(eiId: string): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_EVENT_DESC, true, {eiId, accessCode: window.PublicTicketApp.accessCode, passcode: window.PublicTicketApp.passcode});
	}

	public static fetchFulfillmentEventDescriptor(cartId: string, modstamp: number, eiIds: string, sbslIds: string[]): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_FULFILLMENT_EVENT_DESCRIPTOR, true, {cartId, modstamp, eiIds, sbslIds});
	}

	public static fetchPreviewFulfillmentEventDescriptor(eiId: string, spllIds: string[]): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_PREVIEW_FULFILLMENT_EVENT_DESCRIPTOR, true,
			{eiId, spllIds, accessCode: window.PublicTicketApp.accessCode, passcode: window.PublicTicketApp.passcode}
		);
	}

	public static fetchItemFeeData(cartId: string, activeOnly: boolean = true): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_ITEM_FEE_DATA, true, {cartId, activeOnly});
	}
	
	public static fetchOrderFeeData(cartId: string, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_ORDER_FEE_DATA, nonBlocking, {cartId});
	}

	public static fetchSubscriptionFulfillmentGroups(cartId: string, sbslIds: string[], nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_SUBCRIPTION_FULFILLMENT_GROUPS, nonBlocking, {cartId, sbslIds});
	}

	public static ensureCart(cartId: string, createIfNecessary: boolean = false, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		// Set the user agent string here
		const userAgent = navigator.userAgent || "";
		// PMGR-8325 Set the order source here if it was present on the URL
		const orderSource = window.PublicTicketApp.orderSource || "";
		return vfRequestDispatcher(ApiActionNames.ENSURE_CART, nonBlocking, {cartId, userAgent, orderSource, createIfNecessary});
	}
	
	public static updateCart(isPendingRenewal: boolean = false, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.UPDATE_CART, nonBlocking, (getState: () => RootState) => {
			// Filter out sensitive CC information that shouldn't be sent to the server
			return {cart: getSanitizedCart(isPendingRenewal ? getState().portal.pendingRenewal.cart : getState().cart)};
		});
	}
	
	public static submitCart(isPendingRenewal: boolean = false, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.SUBMIT_CART, nonBlocking, (getState: () => RootState) => (
			{cart: isPendingRenewal ? getState().portal.pendingRenewal.cart : getState().cart}
		));
	}
	
	public static fetchMobileTicketCart(ticketOrderId: string, token: string, eiId: string, toiIds: string[]): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_MOBILE_TICKET_CART, false, {ticketOrderId, token, eiId, toiIds});
	}

	public static fetchApplePass(recordId: string, token: string): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_APPLE_PASS, false, {recordId, token});
	}

	public static fetchGooglePassURL(recordId: string, token: string): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_GOOGLE_PASS_URL, false, {recordId, token});
	}
	
	public static emptyCart(nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		// This is basically equivalent to deleting all items from the cart
		return vfRequestDispatcher(ApiActionNames.DELETE_CART_ITEMS, nonBlocking, (getState: () => RootState) => {
			const {cart} = getState();
			return {cartId: cart.cartId, cartItems: cart.cartItems, modstamp: cart.modstamp};
		});
	}

	public static fetchMembershipCards(tokens: string[]): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_MEMBERSHIP_CARDS, false, {tokens});
	}
	
	/**
	 * Puts provided cartItems into the cart
	 *
	 * @param {string} cartId id of the ticket order, or the cart id
	 * @param {CartItem[]} cartItems the cart items to insert
	 * @param {number | null} modstamp modification timestamp of the last time the cart was modified
	 * @param {boolean} nonBlocking
	 * @returns ThunkAction<Promise<Cart> if successful, otherwise ThunkAction<Promise<VfRemoteErrorResponse>
	 */
	public static insertCartItems(cartId: string, cartItems: CartItem[], modstamp: number | null = null, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.INSERT_CART_ITEMS, nonBlocking, {cartId, cartItems, modstamp, accessCode: window.PublicTicketApp.accessCode});
	}

	/**
	 * Creates new PYOS Subscription Cart Items and inserts them into the Cart. This is used for fixed, single venue PYOS enabled
	 * Subscriptions where we insert and fulfill the subscriptions in a single operation.
	 * 
	 * @param {string} cartId id of the cart to insert item into
	 * @param {CartItem[]} subscriptionCartItems a list of the subscription cart items to be inserted
	 * @param {number | null} modstamp cart's last modification timestamp
	 * @param {boolean} nonBlocking
	 * @returns {ThunkAction<Promise<any>, any, void>}
	 */
	public static insertPYOSSubscriptionCartItems(
		cartId: string,
		subscriptionCartItems: CartItem[],
		modstamp: number | null = null,
		nonBlocking: boolean = false
	): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.INSERT_PYOS_SUBSCRIPTION_CART_ITEMS, nonBlocking, {
			cartId, cartItems: subscriptionCartItems, modstamp, accessCode: window.PublicTicketApp.accessCode
		});
	}
	
	/**
	 * This method supports adding multiple Subscription CartItems in a single request. This only works for non-PYOS
	 * Subscriptions, because it doesn't allow for assignment of seats to the Subscription CartItems
	 * @param cartId
	 * @param subscriptionCartItems
	 * @param selectedTicketPLsBySubscriptionPLMap
	 * @param modstamp
	 * @param nonBlocking
	 */
	public static insertSubscriptionCartItems(
		cartId: string,
		subscriptionCartItems: CartItem[],
		selectedTicketPLsBySubscriptionPLMap: BasicStringKeyedMap<string[]> | null,
		modstamp: number | null = null,
		nonBlocking: boolean = false
	): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.INSERT_SUBSCRIPTION_CART_ITEMS, nonBlocking, {
			cartId, cartItems: subscriptionCartItems, selectedTicketPLsBySubscriptionPLMap, modstamp, accessCode: window.PublicTicketApp.accessCode
		});
	}

	/**
	 * This method supports fulfilling PYOS-able multi-venue fixed subscriptions.
	 * @param cartId
	 * @param fulfillmentItems
	 * @param modstamp
	 * @param nonBlocking
	 */
	public static insertFulfillmentItems(
		cartId: string,
		fulfillmentItems: CartItem[],
		modstamp: number | null = null,
		nonBlocking: boolean = false
	): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.INSERT_FULFILLMENT_ITEMS, nonBlocking, {
			cartId, cartItems: fulfillmentItems, modstamp
		});
	}

	public static updateCartItems(cartId: string, cartItems: CartItem[], modstamp: number | null = null, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.UPDATE_CART_ITEMS, nonBlocking, {cartId, cartItems, modstamp, accessCode: window.PublicTicketApp.accessCode});
	}

	public static deleteCartItems(cartId: string, cartItems: CartItem[], modstamp: number | null = null, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.DELETE_CART_ITEMS, nonBlocking, {cartId, cartItems, modstamp});
	}
	
	public static applyDiscount(cartId: string, discountCode: string, modstamp: number | null = null, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.APPLY_DISCOUNT, nonBlocking, {cartId, discountCode, modstamp});
	}

	public static fetchGiftCardBalance(gcNumber: string, captchaToken: string, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_GIFT_CARD_BALANCE, nonBlocking, {gcNumber, captchaToken});
	}

	public static fetchGiftCardBalanceByPaymentMethodId(paymentMethodId: string, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_GIFT_CARD_BALANCE_BY_PAYMENT_METHOD_ID, nonBlocking, {paymentMethodId});
	}

	public static validatePasscode(passcode: string, eventInstanceId: string, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.VALIDATE_PASSCODE, nonBlocking, {passcode, eventInstanceId});
	}

	public static validateCYOSubscriptionPriceLevels(subscriptionPriceLevelIds: string[], nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.VALIDATE_CYO_SUBSCRIPTION_PRICE_LEVELS, nonBlocking, {subscriptionPriceLevelIds});
	}
	
	//
	// Portal specific
	//

	public static login(userName: string, password: string, retURL: string, cartId: string): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.LOG_IN, false, {userName,password,retURL, cartId});
	}
	
	public static forgotPassword(userName: string): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FORGOT_PASSWORD, false, {userName});
	}
	public static changePassword(oldPassword: string, newPassword: string, verifyPassword: string): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.CHANGE_PASSWORD, false, {oldPassword,newPassword, verifyPassword});
	}

	public static updateUserProfile(user: UserProfile): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.UPDATE_USER_PROFILE, false,{user});
	}

	/**
	 * Gets list of all pending renewals that belong to the portal user
	 *
	 * @param nonBlocking
	 * @returns list of pending renewals
	 */
	public static fetchPortalOrders(nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_PORTAL_ORDERS, nonBlocking);
	}

	public static fetchDonationHistory(nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_PORTAL_DONATIONS, nonBlocking);
	}
	
	public static fetchRenewableBenefits(nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_RENEWABLE_BENEFITS, nonBlocking);
	}
	
	public static fetchPortalOrderDetails(ticketOrderId: string, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_PORTAL_ORDER_DETAILS, nonBlocking, {ticketOrderId});
	}
	
	public static fetchPendingRenewal(ticketOrderId: string, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.FETCH_PENDING_RENEWAL, nonBlocking, {ticketOrderId});
	}

	public static doNotRenew(ticketOrderId: string, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.DO_NOT_RENEW, nonBlocking, {ticketOrderId});
	}
	public static submitContactRequest(whatId: string, request: string, nonBlocking: boolean = false): ThunkAction<Promise<any>, RootState, void, AnyAction> {
		return vfRequestDispatcher(ApiActionNames.SUBMIT_CONTACT_REQUEST, nonBlocking, {whatId, request});
	}

	//
	// Action creators for API requests
	//

	public static apiRequest(actionName: ApiActionNames, nonBlocking: boolean, params: any): AnyAction {
		return {type: ActionTypes.API_REQUEST, requestId: getNextSequenceNumber(), actionName, params, nonBlocking};
	}
	
	public static apiSuccess(requestId: number, actionName: ApiActionNames, params: any, data: any): AnyAction {
		return {type: ActionTypes.API_SUCCESS, requestId, actionName, params, data};
	}
	
	public static apiFailure(requestId: number, actionName: ApiActionNames, params: any, data: any): AnyAction {
		return {type: ActionTypes.API_FAILURE, requestId, actionName, params, errors: ApiActions.getErrorsAsArray(data)};
	}
	
	private static getErrorsAsArray(data: any) {
		let errors;
		if (Array.isArray(data)) {
			errors = data;
		} else {
			errors = [data];
		}
		return errors;
	}
}

function vfRequestDispatcher(actionName: ApiActionNames, nonBlocking: boolean, params?: any): ThunkAction<Promise<any>, RootState, any, AnyAction> {
	// Return a "redux-thunk" that makes the api calls asynchronously and dispatches the appropriate actions
	return (dispatch: Dispatch, getState: () => any): Promise<any> => {

		// If the action needs to supply something from the current state, it can provide a
		// function as the "params" argument to the action generator
		if (typeof params === "function") {
			// Call the function to get the params object
			params = params(getState);
		}
		
		const request = ApiActions.apiRequest(actionName, nonBlocking, params);
		dispatch(request);
		
		// Return a Promise that makes the VF remote call for us
		return vfRemotePromise(request)
			.then((result: any) => {
				return dispatch(ApiActions.apiSuccess(request.requestId, request.actionName, request.params, result));
			})
			.catch((error: any) => {
				return dispatch(ApiActions.apiFailure(request.requestId, request.actionName, request.params, error));
			});
	};
}

export function vfRemotePromise(request: any): Promise<any> {
	// The params object must be passed to the "apply" function in an array
	// @ts-ignore
	return VfRemote[request.actionName].apply(VfRemote, [request.params]);
}

// Generates an incrementing sequence of numbers used as the requestId for API actions (and other actions)
let sequenceNumber = 0;

export function getNextSequenceNumber() {
	sequenceNumber += 1;
	return sequenceNumber;
}

// FOR UNIT TESTING PURPOSES ONLY!
export function resetSequence() {
	sequenceNumber = 0;
}

